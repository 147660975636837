/*  */
/* Maintenance services */
/*  */

import { app, getDBInstance, ObjectId, branchApp } from "./dbServices";

export const updateAppSettings = async ({
    id,
    values,
    fullAndroidMaintenance,
    fullIOSMaintenance,
    maintenanceModeAndroid,
    maintenanceModeIOS,
    type,
    userType,
  }: any) => {
    const selectedApp = userType === "branch" ? branchApp : app;
    if (!id) {
      const result = await getDBInstance(userType).collection("appSettings").insertOne({
        androidMinimumVersion: values?.androidVersion,
        iosMinimumVersion: values?.iOSVersion,
        fullAndroidMaintenance,
        fullIOSMaintenance,
        maintenanceModeAndroid,
        maintenanceModeIOS,
        type,
        metaData: {
          createdAt: new Date(),
          createdBy: values.createdBy || selectedApp.currentUser.id,
          modifiedAt: null,
          modifiedBy: null,
        },
      });
  
      return result;
    }
  
    if (typeof id === "string") {
      id = new ObjectId(id);
    }
  
    const result = await getDBInstance(userType)
      .collection("appSettings")
      .updateOne(
        {
          _id: id,
        },
        {
          $set: {
            androidMinimumVersion: values?.androidVersion,
            iosMinimumVersion: values?.iOSVersion,
            fullAndroidMaintenance,
            fullIOSMaintenance,
            maintenanceModeAndroid,
            maintenanceModeIOS,
            type,
            metaData: {
              createdAt: new Date(),
              createdBy: values.createdBy || selectedApp.currentUser.id,
              modifiedAt: new Date(),
              modifiedBy: values.modifiedBy || selectedApp.currentUser.id,
            },
          },
        }
      );
  
    return result;
  };
  
  export const getAppSettings = async ({ type,userType }: any) => {
  
    const result = await getDBInstance(userType)
      .collection("appSettings")
      .aggregate([
        {
          $match: { type: type },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ]);
  
    return result[0];
  };
import { useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { BsDatabaseFillX } from "react-icons/bs";
import { MdBarChart } from "react-icons/md";
import { useOrders } from "model/queryCalls/queryCalls";
import Widget from "components/widget/Widget";
import { Order } from "./interface";
import dayjs from "dayjs";

const Orders = (branchId: any) => {
  const { data: ordersData, isSuccess, isLoading } = useOrders(branchId);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleWidgetClick = (order: Order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  return (
    <div className="mx-4">
      {isLoading ? (
        <Box>
          <Skeleton sx={{ height: 190 }} />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      ) : isSuccess && ordersData?.data.length === 0 ? (
        <div className="flex w-full flex-col items-center justify-center">
          <BsDatabaseFillX className="h-16 w-16 text-gray-500" />
          <p className="mt-3 text-sm font-bold text-gray-800">No Data</p>
        </div>
      ) : (
        <div className="mt-9 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
          {ordersData?.data.map((order: Order, index: number) => (
            <div key={index} onClick={() => handleWidgetClick(order)}>
              <Widget
                icon={<MdBarChart className="h-7 w-7" />}
                title={`Order Ref: ${order.refNumber}`}
                subtitle={`Total: $${order.amount}`}
              />
            </div>
          ))}
        </div>
      )}

      {/* Modal */}
      {isModalOpen && selectedOrder && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="w-full max-w-lg rounded-lg bg-white p-6 shadow-lg">
            <h2 className="mb-4 text-xl font-bold">Order Details</h2>
            <p>
              <strong>Order Ref:</strong> {selectedOrder.refNumber?.toString()}
            </p>
            <p>
              <strong>Date & Time:</strong>{" "}
              {selectedOrder.metadata.createdAt
                ? dayjs(selectedOrder.metadata.createdAt).format(
                    "MMMM D, YYYY h:mm A"
                  )
                : "N/A"}
            </p>
            <p>
              <strong>Order Type:</strong> {selectedOrder.orderType}
            </p>
            <p>
              <strong>Total Amount:</strong> ${selectedOrder.amount}
            </p>
            <p>
              <strong>Order Status:</strong> {selectedOrder.status}
            </p>
            <p>
              <strong>Payment Status:</strong> {selectedOrder.paymentStatus}
            </p>

            <button
              onClick={closeModal}
              className="mt-4 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Orders;

import { Box, Skeleton } from "@mui/material";
import Card from "components/card";
import { useOneCms } from "model/queryCalls/queryCalls";
import { BsDatabaseFillX } from "react-icons/bs";
import { MdArrowCircleLeft, MdEditSquare } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useCMSPage } from "./useCMS";

const CMSDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: CMSData, status } = useOneCms({ id });
  const { userType } = useCMSPage();

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        <Card extra={"w-full h-full p-3"}>
          {status === "loading" ? (
            <Box>
              <Skeleton sx={{ height: 190 }} />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          ) : status === "success" ? (
            <div className="relative mb-3 items-center justify-between py-5 text-center lg:px-10">
              <div className="mb-auto flex flex-col items-center justify-center">
                <div className="flex w-full flex-row items-center justify-between">
                  <MdArrowCircleLeft
                    className="h-8 w-8 text-navy-700 hover:cursor-pointer hover:text-navy-500 dark:text-white hover:dark:text-gray-600"
                    onClick={() => navigate(-1)}
                  />
                  <MdEditSquare
                    className="h-8 w-8 text-navy-700 hover:cursor-pointer hover:text-navy-500 dark:text-white hover:dark:text-gray-600"
                    onClick={() =>
                      navigate(`/${userType}/edit-cms/${CMSData._id}`)
                    }
                  />
                </div>
              </div>
              {/* Cards */}
              <div className="mt-3 grid grid-cols-1 gap-4 px-2">
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Page Name</p>
                  <p className="text-left font-medium text-navy-700 dark:text-white">
                    {CMSData?.pageName?.en}
                  </p>
                </div>
              </div>

              <div className="mt-3 grid grid-cols-1 gap-4 px-2 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Slug</p>
                  <p className="text-left font-medium text-navy-700 dark:text-white">
                    {CMSData?.slug}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Is Active</p>
                  <p className="text-base font-medium text-navy-700 dark:text-white">
                    {CMSData?.isActive ? "Active" : "In Active"}
                  </p>
                </div>
              </div>
              <div className="mt-3 grid grid-cols-1 gap-4 px-2">
                <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                  <p className="text-sm text-gray-600">Content</p>
                  {/* <p className="text-left font-medium text-navy-700 dark:text-white">
                    {CMSData?.content.en}
                  </p> */}
                  <div
                    className="text-left font-medium text-navy-700 dark:text-white"
                    dangerouslySetInnerHTML={{
                      __html: CMSData?.content?.en,
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex w-full flex-col items-center justify-center">
              <BsDatabaseFillX className="h-16 w-16 text-gray-500" />
              <p className="mt-3 text-sm font-bold text-gray-800">No Data</p>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default CMSDetails;

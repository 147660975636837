import { useQuery } from "react-query";
import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AddCms,
  UpdateCms,
  getCms,
  getOneCms,
  DeleteCms,
} from "controller/services/CMSServices";
import * as Yup from "yup";
import { AlertContext } from "controller/context/alertContext";
import { UserContext } from "controller/context/userContext";

export const useCMSPage = () => {
  const { setAlert } = useContext(AlertContext);
  const { userType } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [cmsData, setCmsData] = useState("");
  const [loading, setLoading] = useState(false);
  const [CMSPageData, setCMSPageData] = useState<any>(null);

  // Fetch CMS list data
  const {
    data: CMSData,
    refetch,
    isLoading: listLoading,
  } = useQuery(["cmsData", page, rowsPerPage, userType], () =>
    getCms({ page, rowsPerPage, userType })
  );

  // Fetch CMS data for edit if 'id' is present
  useEffect(() => {
    const fetchCMSPageData = async () => {
      if (id) {
        const data = await getOneCms({ id, userType });
        setCMSPageData(data);
        setIsActive(data.isActive);
        setCmsData(data.content.en);
      }
    };
    fetchCMSPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const initialValues = {
    pageName: CMSPageData?.pageName?.en || "",
    content: cmsData || "",
  };

  const validationSchema = Yup.object().shape({
    pageName: Yup.string().required("Please enter page name"),
    content: Yup.string().required("Please enter content"),
  });

  // Handle form submit (add or update CMS)
  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const params = {
        values,
        isActive,
        cmsData: values.content,
        userType,
      };

      if (id) {
        await UpdateCms({ id, ...params });
        setAlert({
          active: true,
          type: "success",
          message: "CMS Updated successfully",
        });
      } else {
        await AddCms(params);
        setAlert({
          active: true,
          type: "success",
          message: "CMS added successfully",
        });
      }

      setLoading(false);
      navigate(`/${userType}/all-cms`);
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: "Error while adding CMS",
      });
    }
  };

  // Handle CMS deletion
  const onDelete = async (deleteId: string) => {
    try {
      await DeleteCms({ id: deleteId, userType });
      refetch();
      setAlert({
        active: true,
        type: "success",
        message: "Successfully Deleted",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to delete",
      });
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  return {
    CMSData,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    userType,
    onDelete,
    deleteId,
    setDeleteId,
    isVisible,
    setIsVisible,
    loading: listLoading || loading,
    initialValues,
    validationSchema,
    onSubmit,
    isActive,
    setIsActive,
    cmsData,
    setCmsData,
    CMSPageData,
  };
};

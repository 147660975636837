import React, { useState, useRef, useContext } from "react";
import { AlertContext } from "controller/context/alertContext";
import { uploadToCloudflareDirectly } from "controller/services/cloudflareUpload";

export interface UploadedImage {
  url: string;
  source: string;
  file?: {
    id: string;
    file?: any;
    name?: string;
    size?: number;
    lastModified?: any;
    type?: string;
    url?: string;
    height?: number;
    width?: number;
  };
  name?: string;
}

export interface ImageUploadProps {
  onUploadSuccess?: (image: UploadedImage) => void;
  onUploadError?: (error: Error) => void;
  uploadPath?: string;
  uploadMethod?: "cloudflare" | "imagekit" | any;
}

export const useImageUpload = ({
  uploadPath = "uploads",
  uploadMethod = "cloudflare",
}: ImageUploadProps) => {
  const { setAlert } = useContext(AlertContext);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [genericImage, setGenericImage] = useState<UploadedImage | null>(null);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement> | any,
    method: "cloudflare" | "imagekit" = uploadMethod
  ): Promise<UploadedImage | null> => {
    const file = method === "cloudflare" ? event.target.files?.[0] : event;

    if (!file) {
      setAlert({
        active: true,
        type: "error",
        message: "No file selected",
      });
      return null;
    }

    try {
      setIsUploading(true);
      let uploadedImage: UploadedImage;

      if (method === "cloudflare") {
        const getImageDimensions = (
          file: File
        ): Promise<{ width: number; height: number }> => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () =>
              resolve({ width: img.naturalWidth, height: img.naturalHeight });
            img.onerror = reject;
            img.src = URL.createObjectURL(file);
          });
        };
        const { width, height } = await getImageDimensions(file);
        const uploadedUrl = await uploadToCloudflareDirectly(file, uploadPath);

        uploadedImage = {
          url: uploadedUrl?.imageUrl,
          source: "cloudflare",
          name: file.name,
          file: {
            id: uploadedUrl?.imageId,
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            url: uploadedUrl?.imageUrl,
            width,
            height,
          },
        };
      } else {
        uploadedImage = {
          url: event.url,
          source: "imagekit",
          file: event,
          name: event.name,
        };
      }

      setGenericImage(uploadedImage);

      setAlert({
        active: true,
        type: "success",
        message: "Image uploaded successfully",
      });

      return uploadedImage;
    } catch (error) {
      console.error("Detailed upload error:", error);
      setAlert({
        active: true,
        type: "error",
        message: `Image upload failed: ${
          error instanceof Error ? error.message : "Unknown error"
        }`,
      });
      return null;
    } finally {
      setIsUploading(false);
    }
  };

  const onSuccess = (res: any) => {
    handleFileUpload(res, "imagekit");
  };

  const onError = (error: any) => {
    console.error("Upload Error:", error);
    setIsUploading(false);
    setAlert({
      active: true,
      type: "error",
      message: "Image upload failed",
    });
  };

  const onUploadProgress = () => {
    setIsUploading(true);
  };

  const handleDeleteImage = () => {
    setGenericImage(null);
  };

  return {
    handleFileUpload,
    isUploading,
    imageInputRef,
    onSuccess,
    onError,
    onUploadProgress,
    handleDeleteImage,
    genericImage,
    setGenericImage,
  };
};

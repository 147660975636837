import { app, getDBInstance, ObjectId, branchApp } from "./dbServices";

export const AddMenuValues = async ({ data, userType }: any) => {
  if (!data) {
    return;
  }

  if (!data.branchId) {
    return;
  }
  const selectedApp = userType === "branch" ? branchApp : app;
  const categoryObjectIds = data.category.map((id: string) => new ObjectId(id));
  // const modifierObjectIds = data.modifierIds.map(
  //   (id: string) => id
  // );
  // Insert a new menu item into the "menu" collection
  const result = await getDBInstance(userType)
    .collection("menu")
    .insertOne({
      branchId: data.branchId,
      name: { en: data.name },
      price: data.price,
      description: data.description || "",
      category: categoryObjectIds,
      modifier: data.modifierIds || [],
      image: data.image || null,
      isActive: data.isActive !== undefined ? data.isActive : true,
      metaData: {
        createdAt: new Date(),
        createdBy: data.createdBy || selectedApp.currentUser.id,
        modifiedAt: null,
        modifiedBy: null,
      },
    });

  return result;
};

export const GetMenuValues = async ({
  page = 0,
  rowsPerPage = 10,
  branchId,
  userType,
}: any) => {
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;
  const totalDocumentCount = await getDBInstance(userType)
    .collection("menu")
    .count({ branchId });

  const result = await getDBInstance(userType)
    .collection("menu")
    .aggregate([{ $match: { branchId } }, { $skip: skip }, { $limit: limit }]);
  return { data: result, totalDocumentCount };
};

export const GetOneMenuValue = async ({ id, userType }: any) => {
  if (!id) {
    return;
  }

  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType).collection("menu").findOne({
    _id: id,
  });

  return result;
};

export const UpdateOneMenuStatus = async ({ id, isActive, userType }: any) => {
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType)
    .collection("menu")
    .updateOne(
      { _id: id },
      {
        $set: {
          isActive: isActive,
        },
      }
    );

  return result;
};

export const UpdateMenuValues = async ({ data, userType }: any) => {
  if (!data.id) {
    return;
  }

  if (typeof data.id === "string") {
    data.id = new ObjectId(data.id);
  }
  const selectedApp = userType === "branch" ? branchApp : app;
  const categoryObjectIds = data.category.map((id: string) => new ObjectId(id));

  const result = await getDBInstance(userType)
    .collection("menu")
    .updateOne(
      {
        _id: data.id,
      },
      {
        $set: {
          branchId: data.branchId,
          name: { en: data.name },
          price: data.price,
          category: categoryObjectIds,
          description: data.description || "",
          modifier: data.modifierIds || [],
          image: data.image || null,
          isActive: data.isActive !== undefined ? data.isActive : true,
          metaData: {
            createdAt: new Date(),
            createdBy: data.createdBy || selectedApp.currentUser.id,
            modifiedAt: new Date(),
            modifiedBy: data.modifiedBy || selectedApp.currentUser.id,
          },
        },
      }
    );
  return result;
};

export const DeleteMenu = async ({ id, userType }: any) => {
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType).collection("menu").deleteOne({
    _id: id,
  });
  return result;
};


export const GetCategoriesData = async ({ branchId, userType }: any) => {
  if (!branchId) {
    return null;
  }

  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId);
  }

  try {
    const branchData = await getDBInstance(userType)
      .collection("branch")
      .findOne(
        { _id: branchId },
        { projection: { category: 1 } }
      );

    const categoryIds = branchData?.category || [];

    if (categoryIds.length === 0) {
      return [];
    }

    const categories = await getDBInstance(userType)
      .collection("categories")
      .find(
        { _id: { $in: categoryIds } },
        { projection: { name: 1 } } 
      );

    return categories;
  } catch (error) {
    console.error("Error fetching branch categories with names:", error);
    throw new Error("Could not fetch branch categories with names");
  }
};




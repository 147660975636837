import React, { useContext } from "react";
import { UserContext } from "controller/context/userContext";
import { useNavigate } from "react-router-dom";
import { useAllOrdersList } from "model/queryCalls/queryCalls";
import { Box, Skeleton } from "@mui/material";
import Card from "components/card";
import { BsDatabaseFillX } from "react-icons/bs";
import { MdVisibility } from "react-icons/md";

// Interfaces
interface Order {
  _id: string;
  refNumber: string;
  restaurantName: string;
  orderType: string;
  amount: number;
  status: OrderStatus;
  paymentStatus: PaymentStatus;
}

type OrderStatus = "Pending" | "Completed" | "Cancelled" | "Processing";
type PaymentStatus = "Paid" | "Unpaid" | "Refunded";

interface OrdersResponse {
  data: Order[];
  total: number;
  pages: number;
}

interface EmptyStateProps {
  message: string;
}

interface PaginationButtonProps {
  onClick: () => void;
  disabled?: boolean;
  active?: boolean;
  children: React.ReactNode;
}

// Component Definitions
const LoadingSkeleton: React.FC = () => (
  <Box className="space-y-4">
    {[...Array(5)].map((_, i) => (
      <Skeleton key={i} height={60} />
    ))}
  </Box>
);

const EmptyState: React.FC<EmptyStateProps> = ({ message }) => (
  <div className="flex flex-col items-center justify-center py-12">
    <BsDatabaseFillX className="h-12 w-12 text-gray-400" />
    <h3 className="mt-4 text-lg font-semibold text-gray-900">{message}</h3>
    <p className="mt-1 text-sm text-gray-500">
      No orders to display at the moment.
    </p>
  </div>
);

const PaginationButton: React.FC<PaginationButtonProps> = ({
  onClick,
  disabled = false,
  active = false,
  children,
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`
      relative inline-flex items-center px-4 py-2 text-sm font-medium
      ${
        active
          ? "z-10 bg-blue-600 text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
      }
      ${
        disabled
          ? "cursor-not-allowed bg-gray-100 text-gray-400"
          : "cursor-pointer"
      }
      ${!active && !disabled ? "bg-white" : ""}
      first:rounded-l-md last:rounded-r-md
    `}
  >
    {children}
  </button>
);

const Orders: React.FC = () => {
  const [page, setPage] = React.useState<number>(0);
  const { userType } = useContext(UserContext);
  const navigate = useNavigate();
  const rowsPerPage = 8;

  const { data, isLoading, isError } = useAllOrdersList({
    page,
    rowsPerPage,
  }) as {
    data: OrdersResponse | undefined;
    isLoading: boolean;
    isError: boolean;
  };

  // Calculate total pages based on API response
  const totalPages = data?.pages || 5; // Fallback to 5 if pages not provided

  const handlePageChange = (newPage: number): void => {
    setPage(newPage);
  };

  const handleViewOrder = (orderId: string): void => {
    navigate(`/${userType}/order-details/${orderId}`);
  };

  // Generate page numbers to display
  const getPageNumbers = (): number[] => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(0, page - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages - 1, startPage + maxVisiblePages - 1);

    // Adjust start page if we're near the end
    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(0, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const renderPagination = () => (
    <div className="mt-4 flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <PaginationButton
          onClick={() => handlePageChange(Math.max(0, page - 1))}
          disabled={page === 0}
        >
          Previous
        </PaginationButton>
        <PaginationButton
          onClick={() => handlePageChange(Math.min(totalPages - 1, page + 1))}
          disabled={page >= totalPages - 1}
        >
          Next
        </PaginationButton>
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing page <span className="font-medium">{page + 1}</span> of{" "}
            <span className="font-medium">{totalPages}</span>
          </p>
        </div>
        <div className="inline-flex -space-x-px rounded-md shadow-sm">
          <PaginationButton
            onClick={() => handlePageChange(0)}
            disabled={page === 0}
          >
            First
          </PaginationButton>

          <PaginationButton
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 0}
          >
            Previous
          </PaginationButton>

          {getPageNumbers().map((pageNum) => (
            <PaginationButton
              key={pageNum}
              onClick={() => handlePageChange(pageNum)}
              active={page === pageNum}
            >
              {pageNum + 1}
            </PaginationButton>
          ))}

          <PaginationButton
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= totalPages - 1}
          >
            Next
          </PaginationButton>

          <PaginationButton
            onClick={() => handlePageChange(totalPages - 1)}
            disabled={page >= totalPages - 1}
          >
            Last
          </PaginationButton>
        </div>
      </div>
    </div>
  );

  return (
    <div className="mt-10 w-full">
      <Card extra="w-full p-4">
        <div className="relative">
          {isLoading ? (
            <LoadingSkeleton />
          ) : isError ? (
            <EmptyState message="Failed to fetch orders" />
          ) : data?.data?.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left  font-semibold uppercase tracking-wider ">
                        Order Ref
                      </th>
                      {userType !== "branch" && (
                        <th className="px-6 py-3 text-left font-semibold uppercase tracking-wider">
                          Restaurant
                        </th>
                      )}
                      <th className="px-6 py-3 text-left  font-semibold uppercase tracking-wider ">
                        Type
                      </th>
                      <th className="px-6 py-3 text-left  font-semibold uppercase tracking-wider ">
                        Amount
                      </th>
                      <th className="px-6 py-3 text-left  font-semibold uppercase tracking-wider ">
                        Status
                      </th>
                      <th className="px-6 py-3 text-left  font-semibold uppercase tracking-wider ">
                        Payment
                      </th>
                      <th className="px-6 py-3 text-center  font-semibold uppercase tracking-wider ">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.data.map((order: Order) => (
                      <tr
                        key={order._id}
                        className="transition-all hover:bg-gray-50"
                      >
                        <td className="whitespace-nowrap px-6 py-4">
                          <span className="font-medium text-gray-900">
                            {order.refNumber}
                          </span>
                        </td>
                        {userType !== "branch" && (
                          <td className="whitespace-nowrap px-6 py-4">
                            {order.restaurantName || "Unknown"}
                          </td>
                        )}

                        <td className="whitespace-nowrap px-6 py-4  ">
                          {order.orderType || "Unknown"}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          <span className="font-medium text-gray-900">
                            ₹{order.amount}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          <span
                            className={`inline-flex rounded-full px-3 py-1 text-xs font-medium ${getStatusStyles(
                              order.status
                            )}`}
                          >
                            {order.status}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          <span
                            className={`inline-flex rounded-full px-3 py-1 text-xs font-medium ${getPaymentStyles(
                              order.paymentStatus
                            )}`}
                          >
                            {order.paymentStatus}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-center">
                          <button
                            onClick={() => handleViewOrder(order._id)}
                            className="inline-flex items-center justify-center gap-2 rounded-lg bg-blue-50 px-4 py-2 text-sm font-medium text-blue-600 transition-colors duration-200 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          >
                            <MdVisibility className="h-5 w-5" />
                            <span>View</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {renderPagination()}
            </>
          ) : (
            <EmptyState message="No orders found" />
          )}
        </div>
      </Card>
    </div>
  );
};

const getStatusStyles = (status: OrderStatus): string => {
  const styles: Record<OrderStatus, string> = {
    Pending: "bg-yellow-100 text-yellow-800",
    Completed: "bg-green-100 text-green-800",
    Cancelled: "bg-red-100 text-red-800",
    Processing: "bg-blue-100 text-blue-800",
  };
  return styles[status] || "bg-gray-100 text-gray-800";
};

const getPaymentStyles = (status: PaymentStatus): string => {
  const styles: Record<PaymentStatus, string> = {
    Paid: "bg-green-100 text-green-800",
    Unpaid: "bg-red-100 text-red-800",
    Refunded: "bg-purple-100 text-purple-800",
  };
  return styles[status] || "bg-gray-100 text-gray-800";
};

export default Orders;

import React, { createContext, useState, useEffect, ReactNode } from "react";
import * as Realm from "realm-web";
import { app, branchApp } from "../services/dbServices";

interface UserContextType {
  user: Realm.User | null;
  isVerified: boolean;
  userType: string;
  loading: boolean;
  emailPasswordLogin: (
    email: string,
    password: string,
    userType: string
  ) => Promise<any>;
  logOutUser: () => Promise<void>;
  fetchUser: () => Promise<Realm.User | false>;
  currentApp: Realm.App | null;
}

export const UserContext = createContext<UserContextType | null>(null);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<Realm.User | null>(null);
  const [currentApp, setCurrentApp] = useState<Realm.App | null>(null);
  const [userType, setUserType] = useState<string>(
    localStorage.getItem("userType") || "branch"
  );
  const [isVerified, setIsVerified] = useState<boolean>(
    JSON.parse(localStorage.getItem("isVerified") || "false")
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const initializeUser = async () => {
      try {
        const savedUserType = localStorage.getItem("userType") || "branch";
        const savedIsVerified = JSON.parse(
          localStorage.getItem("isVerified") || "false"
        );
        setUserType(savedUserType);
        setIsVerified(savedIsVerified);

        let currentUser: Realm.User | null = null;
        let selectedApp: Realm.App | null = null;

        if (app.currentUser) {
          currentUser = app.currentUser;
          selectedApp = app;
        } else if (branchApp.currentUser) {
          currentUser = branchApp.currentUser;
          selectedApp = branchApp;
        }

        if (currentUser) {
          await currentUser.refreshCustomData();
          setUser(currentUser);
          setCurrentApp(selectedApp);
        }
      } catch (error) {
        console.error("Error initializing user:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeUser();
  }, []);

  const emailPasswordLogin = async (
    email: string,
    password: string,
    userType: string
  ) => {
    const credentials = Realm.Credentials.emailPassword(email, password);
    const selectedApp = userType === "branch" ? branchApp : app;
    setUserType(userType);
    localStorage.setItem("userType", userType);

    try {
      const authUser = await selectedApp.logIn(credentials);
      setCurrentApp(selectedApp);
      setUser(authUser);
      return { authUser, selectedApp };
    } catch (error) {
      throw new Error("Login failed: " + error);
    }
  };

  const logOutUser = async () => {
    if (currentApp && currentApp.currentUser) {
      await currentApp.currentUser.logOut();
      localStorage.removeItem("userType");
      localStorage.setItem("isVerified", JSON.stringify(false));
      setUser(null);
      setCurrentApp(null);
      setIsVerified(false);
    }
  };

  const fetchUser = async () => {
    let currentUser = null;
    let selectedApp = null;

    if (app.currentUser && app.currentUser.isLoggedIn) {
      currentUser = app.currentUser;
      selectedApp = app;
    } else if (branchApp.currentUser && branchApp.currentUser.isLoggedIn) {
      currentUser = branchApp.currentUser;
      selectedApp = branchApp;
    }

    if (!currentUser) return false;

    try {
      await currentUser.refreshCustomData();
      setUser(currentUser);
      setCurrentApp(selectedApp);
      return currentUser;
    } catch (error) {
      console.error("Error refreshing user data:", error);
      throw error;
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        isVerified,
        userType,
        loading,
        emailPasswordLogin,
        logOutUser,
        fetchUser,
        currentApp,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import axios from "axios";
import { app } from "./dbServices";

const ERROR_FETCH_UPLOAD_URL = "Failed to fetch Cloudflare direct upload URL.";
const ERROR_UPLOAD_FILE = "Failed to upload file to Cloudflare.";

export const getCloudflareDirectUploadURL = async (
  entity: string
): Promise<string> => {
  try {
    const uploadUrlResponse: any = await app.currentUser.callFunction(
      "getImageUploadURL",
      { entity }
    );

    if (uploadUrlResponse?.uploadURL) {
      return uploadUrlResponse.uploadURL;
    }

    throw new Error(ERROR_FETCH_UPLOAD_URL);
  } catch (error) {
    console.error("Error fetching Cloudflare direct upload URL:", error);

    throw new Error(
      error instanceof Error ? error.message : ERROR_FETCH_UPLOAD_URL
    );
  }
};

export const uploadToCloudflareDirectly = async (
  file: File,
  entity: string
): Promise<any> => {
  try {
    const uploadURL = await getCloudflareDirectUploadURL(entity);

    const formData = new FormData();
    formData.append("file", file);

    const uploadResponse: any = await axios.post(uploadURL, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: false,
    });

    console.log("Cloudflare Direct Upload Response:", uploadResponse);

    const imageUrl: any = uploadResponse?.data?.result?.variants[0];
    const imageId: any = uploadResponse?.data?.result?.id;

    if (!imageUrl || !imageId) {
      throw new Error("Missing image URL or ID in Cloudflare upload response.");
    }

    return { imageUrl: imageUrl, imageId: imageId };
  } catch (error) {
    console.error("Error uploading file to Cloudflare:", error);

    throw new Error(error instanceof Error ? error.message : ERROR_UPLOAD_FILE);
  }
};

import { useContext } from "react";
import { UserContext } from "controller/context/userContext";
import { callMongoFunction } from "controller/services/dbServices";
import { GetCategoriesData } from "controller/services/menuServices";
import { useQuery, useMutation } from "react-query";

// Fetch Menu
export const useFetchMenu = (branchId: string, page = 0, rowsPerPage = 10) =>
  useQuery(["menu", branchId, page, rowsPerPage], async () => {
    return await callMongoFunction("Menu", {
      operation: "getMenu",
      data: { branchId, page, rowsPerPage },
    });
  });

// Fetch Single Menu
export const useFetchSingleMenu = (id: string) =>
  useQuery(["menu", id], async () => {
    return await callMongoFunction("Menu", {
      operation: "getSingleMenu",
      data: { id },
    });
  });

// Add Menu
export const useAddMenu = () =>
  useMutation(async (newMenuData) => {
    return await callMongoFunction("Menu", {
      operation: "addMenu",
      data: newMenuData,
    });
  });

// Update Menu
export const useUpdateMenu = () =>
  useMutation(async (updatedMenuData) => {
    return await callMongoFunction("Menu", {
      operation: "updateMenu",
      data: updatedMenuData,
    });
  });

// Delete Menu
export const useDeleteMenu = () =>
  useMutation(async (menuId) => {
    return await callMongoFunction("Menu", {
      operation: "deleteMenu",
      data: { id: menuId },
    });
  });

// Toggle Menu Status (Activate/Deactivate)
export const useToggleMenuStatus = () =>
  useMutation(async ({ id, isActive }: { id: string; isActive: boolean }) => {
    return await callMongoFunction("Menu", {
      operation: "toggleMenuStatus",
      data: { id, isActive },
    });
  });

export const useCategoryData = ({ branchId }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["branchData", user, userType, { branchId }], () =>
    GetCategoriesData({ branchId, userType })
  );
};

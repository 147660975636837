import { ErrorMessage, Field, Form, Formik } from "formik";
import Card from "components/card";
import Switch from "components/switch";
import { useCMSPage } from "./useCMS";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddCMS = () => {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    loading,
    isActive,
    setIsActive,
    cmsData,
    setCmsData,
  } = useCMSPage();

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        <Card extra={"w-full h-full p-3"}>
          <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
            <div className="px-5">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ setFieldValue }) => (
                  <Form>
                    <div className="mb-3">
                      <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                        Page Name*
                      </label>
                      <Field
                        name="pageName"
                        type="textarea"
                        component="textarea"
                        as="textarea"
                        className={
                          "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                        }
                      />
                      <ErrorMessage
                        name="pageName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className="mb-3">
                      <label className="text-sm font-bold">Content*</label>
                      <CKEditor
                         editor={ClassicEditor as any} 
                        data={cmsData}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("content", data);
                          setCmsData(data);
                        }}
                      />
                      <ErrorMessage
                        name="content"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="mb-8 mt-6 flex flex-1 items-center gap-3">
                      <label
                        htmlFor="checkbox8"
                        className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                      >
                        Is Active*
                      </label>
                      <Switch
                        id="switch8"
                        checked={isActive}
                        onChange={() => setIsActive(!isActive)}
                      />
                    </div>

                    <button
                      className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      type="submit"
                    >
                      {loading ? `Saving...` : `Save`}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AddCMS;

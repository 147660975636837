import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AlertContext } from "controller/context/alertContext";
import * as Yup from "yup";
import {
  addCuisine,
  deleteCuisine,
  updateCuisine,
  updateOneCuisineStatus,
} from "controller/services/cuisineServices";
import { useCuisinesList, useOneCuisine } from "model/queryCalls/queryCalls";
import { UserContext } from "controller/context/userContext";
import { useImageUpload } from "components/ ImageUploadUtility";

export const useCuisines = () => {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const { userType } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [cuisinesData, setCuisinesData] = useState({
    data: [],
    totalDocumentCount: 0,
  });
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    isActive: true,
  });

  const {
    handleFileUpload,
    isUploading,
    imageInputRef,
    onSuccess,
    onError,
    onUploadProgress,
    handleDeleteImage,
    genericImage,
    setGenericImage,
  } = useImageUpload({
    uploadPath: "cuisine",
  });

  const navigate = useNavigate();
  const {
    data: fetchedCuisinesData,
    isSuccess,
    status,
    isRefetching,
    refetch,
  } = useCuisinesList({
    page,
    rowsPerPage,
  });

  useEffect(() => {
    if (isSuccess && fetchedCuisinesData) {
      setCuisinesData(fetchedCuisinesData);
    }
  }, [isSuccess, fetchedCuisinesData, isRefetching]);

  const { data: cuisineData, isLoading: isCuisineLoading } = useOneCuisine({
    id,
  });

  useEffect(() => {
    if (id && cuisineData) {
      setInitialValues({
        name: cuisineData.name || "",
        description: cuisineData.description || "",
        isActive:
          cuisineData.isActive !== undefined ? cuisineData.isActive : true,
      });
      setGenericImage(cuisineData.image || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, cuisineData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const onDelete = async (deleteId: string) => {
    try {
      await deleteCuisine({ id: deleteId, userType });

      const updatedCuisines = cuisinesData?.data?.filter(
        (cuisine) => cuisine._id !== deleteId
      );
      setCuisinesData({
        ...cuisinesData,
        data: updatedCuisines,
        totalDocumentCount: cuisinesData.totalDocumentCount - 1,
      });

      setAlert({
        active: true,
        type: "success",
        message: "Cuisine Deleted successfully",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to delete",
      });
    }
  };

  const onToggleActive = async (cuisineId: string, newIsActive: boolean) => {
    try {
      await updateOneCuisineStatus({
        id: cuisineId,
        isActive: !newIsActive,
        userType: userType,
      });
      refetch();

      setAlert({
        active: true,
        type: "success",
        message: "Cuisine status updated successfully",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to update cuisine status",
      });
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter a cuisine name"),
    description: Yup.string().optional(),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const cuisineData = {
        ...values,
        image: genericImage,
      };

      if (id) {
        await updateCuisine({ id, data: cuisineData, userType });
        setAlert({
          active: true,
          type: "success",
          message: "Cuisine Updated successfully",
        });
      } else {
        await addCuisine({ data: cuisineData, userType });
        setAlert({
          active: true,
          type: "success",
          message: "Cuisine Created successfully",
        });
      }
      setTimeout(() => {
        navigate(`/${userType}/all-cuisines`);
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: "Failed to save cuisine",
      });
    }
  };

  return {
    page,
    rowsPerPage,
    navigate,
    userType,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    onToggleActive,
    cuisinesData,
    loading,
    setLoading,
    initialValues,
    setInitialValues,
    validationSchema,
    onSubmit,
    id,
    cuisineData,
    isSuccess,
    status,
    isCuisineLoading,

    handleFileUpload,
    isUploading,
    imageInputRef,
    onSuccess,
    onError,
    onUploadProgress,
    handleDeleteImage,
    genericImage,
  };
};

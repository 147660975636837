import {
  Typography,
  Box,
  Skeleton,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { useRestaurant } from "../../useRestaurant";
import { BsDatabaseFillX } from "react-icons/bs";

interface WorkingHours {
  name: string;
  status: string;
  startTime: string;
  endTime: string;
}

const RestaurantDetails = ({ restaurantData, loading }: any) => {
  const { categoryOptions, cuisineOptions, mapIdsToOptions } = useRestaurant({});
  if (loading) {
    return (
      <Box>
        <Skeleton sx={{ height: 190 }} />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
      </Box>
    );
  }

  if (!restaurantData) {
    return (
      <div className="flex w-full flex-col items-center justify-center">
        <BsDatabaseFillX className="h-16 w-16 text-gray-500" />
        <p className="mt-3 text-sm font-bold text-gray-800">No Data</p>
      </div>
    );
  }

  const { name, description, branchDetails, metaData } = restaurantData;
  const branch = branchDetails?.[0] || {};
  const mappedCategories = mapIdsToOptions(
    branch?.category || [],
    categoryOptions
  );
  const mappedCuisines = mapIdsToOptions(branch?.cuisine || [], cuisineOptions);

  return (
    <Card sx={{ m: 6, border: 1, borderColor: "divider" }}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Restaurant Name: {name?.en || "N/A"}
            </Typography>
            <Typography variant="body1">
              Description: {description || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Type: {branch.type || "N/A"}
            </Typography>
            <Typography variant="body1">
              IsActive: {metaData?.isActive ? "Yes" : "No"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Address and Contact */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Address: {branch?.address?.address || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Contact: {branch?.contactDetails || "N/A"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Cuisine and Category */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Cuisine:{" "}
              {mappedCuisines.length > 0
                ? mappedCuisines.map((cuisine) => cuisine.label).join(", ")
                : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Category:{" "}
              {mappedCategories.length > 0
                ? mappedCategories.map((category) => category.label).join(", ")
                : "N/A"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Logo and Banner */}
          <Grid item xs={12} sm={6}>
            {branch.logo && (
              <Box>
                <Typography variant="body1">Logo</Typography>
                <img
                  src={branch.logo}
                  alt="Restaurant Logo"
                  className="h-[120px] w-[120px]"
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {branch.banner && (
              <Box>
                <Typography variant="body1">Banner</Typography>
                <img
                  src={branch.banner}
                  alt="Restaurant Banner"
                  className="h-[120px] w-[120px]"
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Username and Password */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">Email</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={branch.username || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">Password</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={branch.password || ""}
              disabled
              type="password"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Payment Status and Order Type */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Payment Status: {branch.paymentStatus || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Order Type:{" "}
              {branch?.orderType && branch?.orderType.length > 0
                ? branch?.orderType.join(", ")
                : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Working Hours */}
          <Grid item xs={12}>
            <Typography variant="body1">Working Hours</Typography>
            {branch?.workingHours ? (
              branch.workingHours.map((day: WorkingHours, index: number) => (
                <Typography key={index} variant="body1">
                  {day.name}:{" "}
                  {day.status ? `${day.startTime} - ${day.endTime}` : "Closed"}
                </Typography>
              ))
            ) : (
              <Typography variant="body1">
                No working hours available
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RestaurantDetails;

import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useContext } from "react";
import { UserContext } from "controller/context/userContext";

// Role types
export const ROLES = {
  ADMIN: "admin",
  BRANCH: "branch",
};

// Auth path patterns
export const AUTH_PATHS = {
  ADMIN: "/admin/auth/sign-in",
  BRANCH: "/branch/auth/sign-in",
};

// 2FA paths
export const TFA_PATHS = {
  ADMIN: "/admin/auth/2fa",
  BRANCH: "/branch/auth/2fa",
};

// Helper to extract role from path
export const getPathRole = (path: string) => {
  if (path.startsWith("/admin")) return ROLES.ADMIN;
  if (path.startsWith("/branch")) return ROLES.BRANCH;
  return null;
};

// Helper to check if current path is a sign-in path
export const isAuthPath = (path: string) => {
  return Object.values(AUTH_PATHS).includes(path);
};

// Helper to check if current path is a 2FA path
export const is2FAPath = (path: string) => {
  return Object.values(TFA_PATHS).includes(path);
};

export const RoleGuard = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isVerified, userType } = useContext(UserContext);

  const getAuthStatus = () => {
    const isAuthenticated = user && user.providerType === "local-userpass";
    // const isVerified = JSON.parse(
    //   localStorage.getItem("isVerified") || "false"
    // );
    // const userRole = user?.role?.toLowerCase();

    return {
      isAuthenticated,
      userType,
      isVerified: isAuthenticated && isVerified,
    };
  };

  useEffect(() => {
    const { isAuthenticated, userType, isVerified } = getAuthStatus();
    const currentPath = location.pathname;
    const pathRole = getPathRole(currentPath);

    // Handle root path
    if (currentPath === "/") {
      if (isAuthenticated) {
        if (!isVerified) {
          const tfaPath =
            userType === ROLES.ADMIN ? TFA_PATHS.ADMIN : TFA_PATHS.BRANCH;
          navigate(tfaPath, { state: { from: location }, replace: true });
          return;
        }
        const defaultPath = `/${userType}/default`;
        navigate(defaultPath, { replace: true });
        return;
      }
      // If not authenticated, redirect to branch sign-in
      navigate(AUTH_PATHS.BRANCH, { replace: true });
      return;
    }

    // Handle sign-in pages
    if (isAuthPath(currentPath)) {
      if (isAuthenticated) {
        if (!isVerified) {
          const tfaPath =
            userType === ROLES.ADMIN ? TFA_PATHS.ADMIN : TFA_PATHS.BRANCH;
          navigate(tfaPath, { state: { from: location }, replace: true });
          return;
        }
        const defaultPath = `/${userType}/default`;
        navigate(defaultPath, { replace: true });
        return;
      }
      return; // Allow access to sign-in page if not authenticated
    }

    // Handle 2FA pages
    if (is2FAPath(currentPath)) {
      if (!isAuthenticated) {
        const signInPath =
          pathRole === ROLES.ADMIN ? AUTH_PATHS.ADMIN : AUTH_PATHS.BRANCH;
        navigate(signInPath, { state: { from: location }, replace: true });
        return;
      }
      if (isVerified) {
        const defaultPath = `/${userType}/default`;
        navigate(defaultPath, { replace: true });
        return;
      }
      if (pathRole !== userType) {
        const correctTfaPath =
          userType === ROLES.ADMIN ? TFA_PATHS.ADMIN : TFA_PATHS.BRANCH;
        navigate(correctTfaPath, { state: { from: location }, replace: true });
        return;
      }
      return; // Allow access to correct 2FA page
    }

    // Handle unauthenticated users
    if (!isAuthenticated) {
      const signInPath =
        pathRole === ROLES.ADMIN ? AUTH_PATHS.ADMIN : AUTH_PATHS.BRANCH;
      navigate(signInPath, { state: { from: location }, replace: true });
      return;
    }

    // Handle authenticated but not verified users
    if (!isVerified) {
      const tfaPath =
        userType === ROLES.ADMIN ? TFA_PATHS.ADMIN : TFA_PATHS.BRANCH;
      navigate(tfaPath, { state: { from: location }, replace: true });
      return;
    }

    // Handle authenticated users accessing wrong role routes
    if (pathRole && pathRole !== userType) {
      const defaultPath = `/${userType}/default`;
      navigate(defaultPath, { state: { from: location }, replace: true });
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigate, user]);

  return children;
};

export const DefaultRedirect = (): any => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isVerified, userType } = useContext(UserContext);

  useEffect(() => {
    const currentPath = location.pathname;
    const pathRole = getPathRole(currentPath);

    const isAuthenticated = user && user.providerType === "local-userpass";

    if (currentPath === "/") {
      if (isAuthenticated) {
        if (!isVerified) {
          const tfaPath =
            userType === ROLES.ADMIN ? TFA_PATHS.ADMIN : TFA_PATHS.BRANCH;
          navigate(tfaPath, { state: { from: location }, replace: true });
          return;
        }
        const defaultPath = `/${userType}/default`;
        navigate(defaultPath, { replace: true });
        return;
      }

      // If not authenticated, redirect to appropriate sign-in page
      const signInPath = pathRole
        ? `/${pathRole}/auth/sign-in`
        : `/${ROLES.BRANCH}/auth/sign-in`;
      navigate(signInPath, { replace: true });
      return;
    }

    // Handle authenticated but not verified users
    if (isAuthenticated && !isVerified) {
      const tfaPath =
        userType === ROLES.ADMIN ? TFA_PATHS.ADMIN : TFA_PATHS.BRANCH;
      navigate(tfaPath, { state: { from: location }, replace: true });
      return;
    }

    // Handle authenticated users accessing wrong role routes
    if (pathRole && pathRole !== userType) {
      const defaultPath = `/${userType}/default`;
      navigate(defaultPath, { state: { from: location }, replace: true });
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, user, location]);

  return null;
};

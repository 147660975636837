/* eslint-disable */

import { HiX } from "react-icons/hi";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  MenuItemStyles,
  menuClasses,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";

import {
  MdHome,
  MdNoteAdd,
  MdConstruction,
  MdPeople,
  MdMenuBook,
  MdDining,
  MdFastfood,
  MdKitchen,
} from "react-icons/md";
import React, { useContext } from "react";
import { UserContext } from "controller/context/userContext";

const SidebarContainer = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
  toggled: boolean;
  setToggled: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose, toggled, setToggled } = props;
  const { userType } = useContext(UserContext);

  const menuItemStyles: MenuItemStyles = {
    root: {},
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({}),
    button: {
      [`&.${menuClasses.disabled}`]: {},
      "&:hover": {},
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };
  const [openSubMenu, setOpenSubMenu] = React.useState(null);

  const handleSubMenuClick = (menuKey: string) => {
    if (openSubMenu === menuKey) {
      setOpenSubMenu(null);
    } else {
      setOpenSubMenu(menuKey);
    }
  };

  return (
    <Sidebar collapsed={open} toggled={toggled} breakPoint="md">
      <div
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
        className="bg-white dark:!bg-navy-800 dark:text-white"
      >
        <div className={`mx-[20px] mb-[50px] mt-[30px] flex items-center`}>
          <span
            className="absolute right-4 top-4 block cursor-pointer md:hidden"
            onClick={setToggled}
          >
            <HiX />
          </span>
          {!open && (
            <div className="ml-1 mt-1 h-2.5 font-poppins text-[22px] font-bold uppercase text-navy-700 dark:text-white">
              FineDine
            </div>
          )}
        </div>
        <div style={{ flex: 1, marginBottom: "32px" }}>
          {!open && (
            <div style={{ padding: "0 24px", marginBottom: "8px" }}>
              <div
                className={`h-2.6 ml-1 mt-1 font-poppins text-[12px] font-bold text-navy-700 dark:text-white ${
                  open ? "opacity-0" : "opacity-1"
                }`}
              >
                General
              </div>
            </div>
          )}
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              icon={<MdHome className="h-6 w-6" />}
              className="font-medium hover:bg-navy-200 hover:dark:text-brand-500"
              component={<Link to={`/${userType}/default`} />}
              onClick={() => handleSubMenuClick(null)}
            >
              Dashboard
            </MenuItem>
          </Menu>

          {/* Manage Users (Only for admin) */}
          {userType === "admin" && (
            <>
              {!open && (
                <div style={{ padding: "0 24px", marginBottom: "8px" }}>
                  <div
                    className={`h-2.6 ml-1 mt-1 font-poppins text-[12px] font-bold text-navy-700 dark:text-white ${
                      open ? "opacity-0" : "opacity-1"
                    }`}
                  >
                    Manage Users
                  </div>
                </div>
              )}
              <Menu menuItemStyles={menuItemStyles}>
                <MenuItem
                  icon={<MdPeople className="h-6 w-6" />}
                  className="font-medium dark:text-brand-500"
                  component={<Link to={`/${userType}/all-users`} />}
                >
                  Users
                </MenuItem>
              </Menu>
            </>
          )}

          {/* Manage Restaurant */}
          {!open && (
            <div style={{ padding: "0 24px", marginBottom: "8px" }}>
              <div
                className={`h-2.6 ml-1 mt-1 font-poppins text-[12px] font-bold text-navy-700 dark:text-white ${
                  open ? "opacity-0" : "opacity-1"
                }`}
              >
                Manage Restaurant
              </div>
            </div>
          )}
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              icon={<MdDining className="h-6 w-6" />}
              className="font-medium dark:text-brand-500"
              component={<Link to={`/${userType}/all-restaurant`} />}
            >
              Restaurant
            </MenuItem>

            {/* Categories and Cuisines (Only for admin) */}
            {userType === "admin" && (
              <>
                <MenuItem
                  icon={<MdFastfood className="h-6 w-6" />}
                  className="font-medium dark:text-brand-500"
                  component={<Link to={`/${userType}/all-categories`} />}
                >
                  Categories
                </MenuItem>

                <MenuItem
                  icon={<MdMenuBook className="h-6 w-6" />}
                  className="font-medium dark:text-brand-500"
                  component={<Link to={`/${userType}/all-cuisines`} />}
                >
                  Cuisines
                </MenuItem>
              </>
            )}
          </Menu>

          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              icon={<MdKitchen className="h-6 w-6" />}
              className="font-medium hover:bg-navy-200 hover:dark:text-brand-500"
              component={<Link to={`/${userType}/all-orders`} />}
              onClick={() => handleSubMenuClick(null)}
            >
              Orders
            </MenuItem>
          </Menu>

          {/* App Settings and CMS (Only for admin) */}
          {userType === "admin" && (
            <>
              {!open && (
                <div style={{ padding: "0 24px", marginBottom: "8px" }}>
                  <div
                    className={`h-2.6 ml-1 mt-1 font-poppins text-[12px] font-bold text-navy-700 dark:text-white ${
                      open ? "opacity-0" : "opacity-1"
                    }`}
                  >
                    App Settings
                  </div>
                </div>
              )}
              <Menu menuItemStyles={menuItemStyles}>
                <SubMenu
                  label="CMS"
                  icon={<MdNoteAdd className="h-6 w-6" />}
                  className="hover:dark:text-brand-500"
                  open={openSubMenu === "cms"}
                  onClick={() => handleSubMenuClick("cms")}
                >
                  <MenuItem
                    className="font-medium dark:text-brand-500"
                    component={<Link to={`/${userType}/all-cms`} />}
                  >
                    All CMS Pages
                  </MenuItem>

                  <MenuItem
                    className="font-medium dark:text-brand-500"
                    component={<Link to={`/${userType}/add-cms`} />}
                  >
                    Add CMS Page
                  </MenuItem>
                </SubMenu>

                <MenuItem
                  icon={<MdConstruction className="h-6 w-6" />}
                  className="font-medium hover:bg-navy-200 hover:dark:text-brand-500"
                  component={<Link to={`/${userType}/maintenance-mode`} />}
                  onClick={() => handleSubMenuClick(null)}
                >
                  Maintenance Mode
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default SidebarContainer;

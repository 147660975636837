import { callMongoFunction, ObjectId } from "controller/services/dbServices";
import { useMutation } from "react-query";

interface UpdateOrderParams {
  orderId: string;
  orderStatus: string;
}

export const useUpdateOrder = () =>
  useMutation(async ({ orderId, orderStatus }: UpdateOrderParams) => {
    return await callMongoFunction("orders", {
      functionName: "changeOrderStatus",
      params: { orderId: new ObjectId(orderId), orderStatus },
    });
  });

import { app, getDBInstance, ObjectId, branchApp } from "./dbServices";

/* Cuisine services */

export const addCuisine = async ({ data,userType }: any) => {

  if (!data) {
    return;
  }
  const selectedApp = userType === "branch" ? branchApp : app;

  const result = await getDBInstance(userType)
    .collection("cuisines")
    .insertOne({
      name: data.name,
      description: data.description || "",
      image: data.image || null,
      metaData: {
        createdAt: new Date(),
        createdBy: data.createdBy || selectedApp.currentUser.id,
        modifiedAt: null,
        modifiedBy: null,
        isActive: data.isActive !== undefined ? data.isActive : true,
      },
    });
  return result;
};

export const updateCuisine = async ({ id, data,userType }: any) => {
  if (!id || !data) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }
  const selectedApp = userType === "branch" ? branchApp : app;

  const result = await getDBInstance(userType)
    .collection("cuisines")
    .updateOne(
      { _id: id },
      {
        $set: {
          name: data.name,
          description: data.description || "",
          image: data.image || null,
          metaData: {
            createdAt: new Date(),
            createdBy: data.createdBy || selectedApp.currentUser.id,
            modifiedAt: new Date(),
            modifiedBy: data.modifiedBy || selectedApp.currentUser.id,
            isActive: data.isActive !== undefined ? data.isActive : true,
          },
        },
      }
    );
  return result;
};

export const deleteCuisine = async ({ id ,userType}: any) => {
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType).collection("cuisines").deleteOne({
    _id: id,
  });
  return result;
};

export const getCuisines = async ({ page = 0, rowsPerPage = 10,userType }: any) => {

  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const totalDocumentCount = await getDBInstance(userType)
    .collection("cuisines")
    .count({});

  const result = await getDBInstance(userType)
    .collection("cuisines")
    .aggregate([{ $skip: skip }, { $limit: limit }]);

  return { data: result, totalDocumentCount };
};

export const getOneCuisine = async ({ id ,userType}: any) => {

  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType).collection("cuisines").findOne({
    _id: id,
  });

  return result;
};

export const updateOneCuisineStatus = async ({ id, isActive ,userType}: any) => {

  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType)
    .collection("cuisines")
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          "metaData.isActive": isActive,  
        },
      }
    );
  return result;
};

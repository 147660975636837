import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "components/card";
import { useRestaurant } from "../useRestaurant";
import RestaurantDetails from "./components/restaurantDetails";
import ModifierView from "./components/modifierView";
import MenuView from "./components/menuView";
import OrdersView from "./components/ordersView";

const ViewRestaurant = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { loading, setRestaurantId, oneRestaurantData, userType, branchId } =
    useRestaurant({});

  React.useEffect(() => {
    setRestaurantId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    "Restaurant Details",
    "Modifier View",
    "Menu View",
    "Orders View",
  ];

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleReset = () => setActiveStep(0);

  const handleEditRestaurant = (restaurantId: string) => {
    navigate(`/${userType}/edit-restaurant/${restaurantId}`);
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <RestaurantDetails
            restaurantData={oneRestaurantData}
            loading={loading}
          />
        );
      case 1:
        return (
          <ModifierView
            modifiersData={
              oneRestaurantData?.branchDetails?.[0]?.modifiers || []
            }
          />
        );
      case 2:
        return (
          <MenuView
            menuData={oneRestaurantData?.branchDetails?.[0]?.menuItems || []}
          />
        );
      case 3:
        return <OrdersView branchId={branchId} />;
      default:
        return <Typography variant="h6">Unknown step</Typography>;
    }
  };

  const handleStep = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: "100%", mb: 10, position: "relative" }}>
      <Stepper activeStep={activeStep} sx={{ mt: 4 }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel onClick={() => handleStep(index)} style={{ cursor: 'pointer' }}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you're finished
          </Typography>
          <Button onClick={handleReset}>Reset</Button>
        </>
      ) : (
        <>
          <Card extra={"w-full h-full mt-4 mb-4"}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                mt: 1,
                mr: 2,
                mb: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleEditRestaurant(id!)}
              >
                Edit Details
              </Button>
            </Box>
            <Box sx={{ mt: 2, mb: 1 }}>{renderStepContent(activeStep)}</Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </Card>
        </>
      )}
    </Box>
  );
};

export default ViewRestaurant;

import Card from "components/card";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import { useCategories } from "./useCategories";
import { MdClose, MdFileUpload } from "react-icons/md";
import { IKUpload, IKContext } from "imagekitio-react";
import {
  publicKey,
  urlEndpoint,
  authenticator,
} from "controller/common/imageKit/imageKit";
import envConfig from "../../../env/env.json";

const AddCategories = () => {
  const {
    loading,
    initialValues,
    validationSchema,
    onSubmit,
    id,
    handleFileUpload,
    isUploading,
    imageInputRef,
    onSuccess,
    onError,
    onUploadProgress,
    handleDeleteImage,
    genericImage,
  } = useCategories();

  return (
    <div className="relative flex w-full flex-col gap-5">
      {/* {isUploading && envConfig.IMAGE_UPLOAD_METHOD === "cloudflare" && (
        <>
          <div className="bg-black absolute inset-0 z-20 bg-opacity-50">
            <div className="flex h-full w-full items-center justify-center">
              <CircularProgress size={80} className="text-white" />
            </div>
          </div>
          <div className="absolute inset-0 z-10 bg-gray-500 opacity-50 backdrop-blur-lg"></div>
        </>
      )} */}

      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        <Card extra={"w-full h-full p-3"}>
          <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
            <div className="px-5">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                <Form>
                  {/* Category Name */}
                  <div className="mb-3">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Category Name*
                    </label>
                    <Field
                      type="text"
                      name="name"
                      className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  {/* Description */}
                  <div className="mb-3">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Description
                    </label>
                    <Field
                      as="textarea"
                      name="description"
                      className="mt-2 flex h-24 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  {/* Image Upload */}
                  <div className="mb-3 mt-4">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Category Image
                    </label>
                    <Card className="grid h-full w-full grid-cols-1 gap-3 rounded-[20px] bg-white p-3">
                      {genericImage && !isUploading ? (
                        <div className="relative flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700">
                          <img
                            src={genericImage.url}
                            alt={genericImage.name}
                            className="h-[120px] w-[120px] object-cover"
                          />
                          <button
                            type="button"
                            className="absolute right-1 top-1"
                            onClick={handleDeleteImage}
                          >
                            <MdClose className="h-6 w-6" />
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => imageInputRef.current?.click()}
                          type="button"
                          className="flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700"
                        >
                          {isUploading ? (
                            <CircularProgress className="z-40 h-[80px] w-[80px]" />
                          ) : (
                            <MdFileUpload className="text-[80px] text-brand-500 dark:text-white" />
                          )}
                          <h4 className="text-xl font-bold text-brand-500 dark:text-white">
                            Upload Image
                          </h4>
                        </button>
                      )}

                      <IKContext
                        publicKey={publicKey}
                        urlEndpoint={urlEndpoint}
                        authenticator={authenticator}
                      >
                        {envConfig.IMAGE_UPLOAD_METHOD === "imagekit" ? (
                          <IKUpload
                            onError={onError}
                            onSuccess={onSuccess}
                            onUploadStart={onUploadProgress}
                            folder={"/categories"}
                            tags={["category-uploads"]}
                            ref={imageInputRef}
                            style={{ display: "none" }}
                            accept="image/*"
                          />
                        ) : (
                          <input
                            type="file"
                            ref={imageInputRef}
                            onChange={(e) => handleFileUpload(e, "cloudflare")}
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                        )}
                      </IKContext>
                    </Card>
                  </div>

                  <button
                    className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    type="submit"
                  >
                    {loading ? `Saving...` : id ? `Update` : `Save`}
                  </button>
                </Form>
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AddCategories;

import { useQuery } from "react-query";
import { useContext } from "react";
import { UserContext } from "controller/context/userContext";
import { getAppSettings } from "controller/services/maintenanceServices";
import {
  getCustomers,
  getOneCustomer,
  getOneUser,
  getUsers,
} from "controller/services/userServices";
import { getCms, getOneCms } from "controller/services/CMSServices";
import {
  getCategories,
  getOneCategory,
} from "controller/services/categoryServices";
import {
  getModifiers,
  getOneModifier,
} from "controller/services/modifierServices";
import {
  getRestaurantValues,
  getOneRestaurant,
} from "controller/services/restaurantServices";
import {
  getCuisines,
  getOneCuisine,
} from "controller/services/cuisineServices";
import {
  getAllOrders,
  getOneOrder,
  getOrders,
  getOrdersOfBranch,
} from "controller/services/orderServices";
import {
  GetOneMenuValue,
  GetMenuValues,
} from "controller/services/menuServices";

export const useAppSettings = ({ type }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["appSettings", user, userType, { type }], () =>
    getAppSettings({ type, userType })
  );
};

//users
export const useUsersList = ({ page, rowsPerPage }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["usersData", user, page, rowsPerPage, userType], () =>
    getUsers({ page, rowsPerPage, userType })
  );
};

export const useOneUser = ({ id }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["oneUser", user, userType, { id }], () =>
    getOneUser({ id, userType })
  );
};

export const useCustomers = ({ page, rowsPerPage }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["customerData", user, userType, page, rowsPerPage], () =>
    getCustomers({ page, rowsPerPage, userType })
  );
};

export const useOneCustomer = ({ id }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["oneCustomer", user, userType, { id }], () =>
    getOneCustomer({ id, userType })
  );
};

export const useCms = ({ page, rowsPerPage }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["cmsData", user, userType, page, rowsPerPage], () =>
    getCms({ page, rowsPerPage, userType })
  );
};

export const useOneCms = ({ id }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["onePoster", user, userType, { id }], () =>
    getOneCms({ id, userType })
  );
};

export const useRestaurantValues = ({ page, rowsPerPage }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["restaurantData", user, page, rowsPerPage, userType], () =>
    getRestaurantValues({ page, rowsPerPage, userType })
  );
};

export const useOneRestaurant = ({ id }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["oneRestaurant", user, userType, { id }], () =>
    getOneRestaurant({ id, userType })
  );
};

export const useCategoriesList = ({ page, rowsPerPage }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["categoriesData", user, userType, page, rowsPerPage], () =>
    getCategories({ page, rowsPerPage, userType })
  );
};

export const useOneCategory = ({ id }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["oneCategory", user, userType, { id }], () =>
    getOneCategory({ id, userType })
  );
};

export const useModifiersList = ({ page, rowsPerPage, branchId }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(
    ["modifiersData", user, userType, page, rowsPerPage, branchId],
    () => getModifiers({ page, rowsPerPage, branchId, userType }),
    {
      enabled: !!branchId,
    }
  );
};

export const useOneModifier = ({ id }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["oneModifier", user, userType, { id }], () =>
    getOneModifier({ id, userType })
  );
};

export const useCuisinesList = ({ page, rowsPerPage }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["cuisinesData", user, userType, page, rowsPerPage], () =>
    getCuisines({ page, rowsPerPage, userType })
  );
};

export const useOneCuisine = ({ id }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(["oneCuisine", user, userType, { id }], () =>
    getOneCuisine({ id, userType })
  );
};

export const useOrders = ({ page, rowsPerPage, branchId }: any) => {
  const { user, userType } = useContext(UserContext);
  return useQuery(
    ["ordersData", user, userType, page, rowsPerPage, branchId],
    () => getOrdersOfBranch({ page, rowsPerPage, userType, branchId })
  );
};
export const useOrdersList = ({
  page = 0,
  rowsPerPage = 10,
  filter = null,
  branchId = null,
}: any) => {
  const { user, userType } = useContext(UserContext);

  return useQuery(
    ["ordersData", user, userType, page, rowsPerPage, filter, branchId],
    () => getOrders({ page, rowsPerPage, userType, filter, branchId }),
    {
      keepPreviousData: true,
    }
  );
};

export const useAllOrdersList = ({ page, rowsPerPage }: any) => {
  const { userType } = useContext(UserContext);
  return useQuery(
    ["ordersList", page, rowsPerPage, userType],
    () => getAllOrders({ page, rowsPerPage, userType }),
    {
      keepPreviousData: true,
    }
  );
};
export const useOneOrder = ({ orderId }: any) => {
  const { userType } = useContext(UserContext);
  return useQuery(
    ["order", userType],
    () => getOneOrder({ userType, orderId }),
    {
      keepPreviousData: true,
    }
  );
};

export const useMenuList = ({ page, rowsPerPage, branchId, userType }: any) => {
  return useQuery(
    ["menuList", page, rowsPerPage, userType],
    () => GetMenuValues({ page, rowsPerPage, branchId, userType }),
    {
      keepPreviousData: true,
    }
  );
};

export const useOneMenu = ({ id, userType }: any) => {
  return useQuery(
    ["oneMenu", { id }],
    () => GetOneMenuValue({ id, userType }),
    {
      enabled: !!id,
    }
  );
};

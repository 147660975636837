import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "controller/context/alertContext";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  deleteCategory,
  updateCategory,
  addCategory,
  UpdateOneCategoryStatus,
} from "controller/services/categoryServices";
import { useOneCategory, useCategoriesList } from "model/queryCalls/queryCalls";
import { UserContext } from "controller/context/userContext";
import { useImageUpload } from "components/ ImageUploadUtility";

interface Category {
  _id: string;
  name: string;
  description: string;
  isActive: boolean;
}

interface CategoriesResponse {
  data: Category[];
  totalDocumentCount: number;
}

export const useCategories = () => {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const { userType } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState<CategoriesResponse>({
    data: [],
    totalDocumentCount: 0,
  });

  const {
    handleFileUpload,
    isUploading,
    imageInputRef,
    onSuccess,
    onError,
    onUploadProgress,
    handleDeleteImage,
    genericImage,
    setGenericImage,
  } = useImageUpload({
    uploadPath: "categories",
  });
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    isActive: true,
  });
  const navigate = useNavigate();
  const {
    data: fetchedCategoriesData,
    isSuccess,
    status,
    isRefetching,
    refetch,
  } = useCategoriesList({
    page,
    rowsPerPage,
  });

  useEffect(() => {
    if (isSuccess && fetchedCategoriesData) {
      setCategoriesData(fetchedCategoriesData);
    }
  }, [isSuccess, fetchedCategoriesData, isRefetching]);

  const { data: categoryData } = useOneCategory({
    id,
  });

  useEffect(() => {
    if (id && categoryData) {
      setInitialValues({
        name: categoryData.name || "",
        description: categoryData.description || "",
        isActive:
          categoryData.isActive !== undefined ? categoryData.isActive : true,
      });
      setGenericImage(categoryData.image || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, categoryData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const onDelete = async (deleteId: string) => {
    try {
      await deleteCategory({ id: deleteId, userType });

      const updatedCategories = categoriesData?.data?.filter(
        (category) => category._id !== deleteId
      );
      setCategoriesData({
        ...categoriesData,
        data: updatedCategories,
        totalDocumentCount: categoriesData.totalDocumentCount - 1,
      });

      setAlert({
        active: true,
        type: "success",
        message: "Category Deleted successfully",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to delete",
      });
    }
  };

  const onToggleActive = async (categoryId: string, newIsActive: boolean) => {
    try {
      await UpdateOneCategoryStatus({
        id: categoryId,
        isActive: !newIsActive,
        userType: userType,
      });
      refetch();

      setAlert({
        active: true,
        type: "success",
        message: "Category status updated successfully",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to update category status",
      });
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter a category name"),
    description: Yup.string().optional(),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const categoryData = {
        ...values,
        image: genericImage,
      };

      if (id) {
        await updateCategory({ id, data: categoryData, userType });
        setAlert({
          active: true,
          type: "success",
          message: "Category Updated successfully",
        });
      } else {
        await addCategory({ data: categoryData, userType });
        setAlert({
          active: true,
          type: "success",
          message: "Category Created successfully",
        });
      }
      setTimeout(() => {
        navigate(`/${userType}/all-categories`);
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: "Failed to save category",
      });
    }
  };

  return {
    page,
    rowsPerPage,
    userType,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    onToggleActive,
    categoriesData,
    status,
    loading,
    initialValues,
    validationSchema,
    onSubmit,
    id,
    handleFileUpload,
    isUploading,
    imageInputRef,
    onSuccess,
    onError,
    onUploadProgress,
    handleDeleteImage,
    genericImage,
  };
};

import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { UserContext } from "controller/context/userContext";
import { AlertContext } from "controller/context/alertContext";
import { Alert, Snackbar } from "@mui/material";
import { EmailResponse } from "./interface";
import { sendEmailAfterLogin } from "controller/services/authServices";
// import { useParams } from "react-router-dom";
import Footer from "components/footer/FooterAuthDefault";
import { getPathRole } from "controller/services/roleGuard";

export default function SignIn() {
  const navigate = useNavigate();
  // const { userType } = useParams();
  const [loading, setLoading] = React.useState(false);
  const {emailPasswordLogin} = React.useContext(UserContext);
  const { setAlert, active, type, message } = React.useContext(AlertContext);

  const currentPath = window.location.pathname;
  const pathRole = getPathRole(currentPath);


  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter your email"),
    password: Yup.string().required("Please enter your password"),
  });

  // React.useEffect(() => {
  //   if (!userType) {
  //     navigate("/branch/auth/sign-in", { replace: true });
  //   } else {
  //     loadUser();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userType]);

  // const loadUser = async () => {
  //   if (!user) {
  //     const fetchedUser = await fetchUser();
  //     if (fetchedUser) {
  //       navigate(`/${userType}/default`);
  //     }
  //   }
  // };

  const onSubmit = async (values: any) => {
    localStorage.setItem("isVerified", JSON.stringify(false));
    setLoading(true);
    try {
      const { authUser, selectedApp } = await emailPasswordLogin(
        values.email,
        values.password,
        pathRole
      );

      if (authUser) {
        if (selectedApp.currentUser) {
          localStorage.setItem(
            "authUser",
            JSON.stringify(selectedApp.currentUser)
          );

          const isEmailSent = (await sendEmailAfterLogin({
            toEmail: values.email,
            currentApp: selectedApp,
          })) as EmailResponse;
          if (isEmailSent?.status === 202 || isEmailSent?.status === 200) {
            setAlert({
              active: true,
              type: "success",
              message: "Email OTP sent to verify your account.",
            });
            setTimeout(() => {
              navigate(`/${pathRole}/auth/2fa`);
            }, 1000);
          } else {
            setAlert({
              active: true,
              type: "error",
              message: "Email not sent. Please try again later.",
            });
            setLoading(false);
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Login error:", error);
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: "Login Failed!",
      });
    }
  };

  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mx-auto mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
                  <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                    <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                      Sign In
                    </h4>
                    <p className="mb-9 ml-1 text-base text-gray-600">
                      Enter your email and password to sign in!
                    </p>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      <Form>
                        <div className="mb-3">
                          <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                            Email*
                          </label>
                          <Field
                            type="email"
                            name="email"
                            className={
                              "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                            }
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-red-500"
                          />
                        </div>

                        <div className="mb-3">
                          <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                            Password*
                          </label>
                          <Field
                            type="password"
                            name="password"
                            className={
                              "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                            }
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                        <button
                          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
                          type="submit"
                        >
                          {loading ? "Loading..." : "Sign In"}
                        </button>
                      </Form>
                    </Formik>
                  </div>
                  <Snackbar
                    open={active}
                    autoHideDuration={3000}
                    onClose={() => {}}
                    anchorOrigin={{ horizontal: "center", vertical: "top" }}
                  >
                    <Alert
                      severity={type}
                      className="mx-3 my-3"
                      variant="filled"
                      sx={{ width: "100%" }}
                    >
                      {message}
                    </Alert>
                  </Snackbar>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

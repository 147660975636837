import * as Realm from "realm-web";
import envConfig from "../../env/env.json";

export const app = new Realm.App({ id: envConfig.MONGODB_APP_ID });
export const branchApp = new Realm.App({ id: envConfig.MONGODB_BRANCH_APP_ID });
export const gApp = new Realm.App({ id: envConfig.MONGODB_GENERAL_APP_ID });

export const {
  BSON: { ObjectId },
} = Realm;
// export const getDBInstance = () => {
//   if (!app || !app.currentUser) {
//     return;
//   }
//   return app.currentUser.mongoClient("mongodb-atlas").db(envConfig.MONGODB_DB);
// };

export const getDBInstance = (userType: string) => {
  const selectedApp = userType === "branch" ? branchApp : app;
  if (!selectedApp || !selectedApp.currentUser) {
    return;
  }
  return selectedApp.currentUser
    .mongoClient("mongodb-atlas")
    .db(envConfig.MONGODB_DB);
};

export const generalLogin = async () => {
  const gUser = await gApp.logIn(
    Realm.Credentials.apiKey(envConfig.MONGODB_GENERAL_APP_KEY)
  );
  return gUser;
};

export const callMongoFunction = async <T>(
  fnName: string,
  payload: object
): Promise<T> => {
  try {
    const gUser = await generalLogin();
    const response = await gUser.callFunction(fnName, payload);
    return response as T;
  } catch (error: any) {
    console.error(`Error calling ${fnName}:`, error.message);
    throw new Error(`Failed to execute ${fnName}`);
  }
};

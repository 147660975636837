import { branchApp, getDBInstance, ObjectId } from "./dbServices";
import dayjs from "dayjs";

/* Order services */

export const getOrdersOfBranch = async ({
  page = 0,
  rowsPerPage = 10,
  userType,
  branchId,
}: any) => {
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  // Count documents that match the branchId
  const totalDocumentCount = await getDBInstance(userType)
    .collection("orders")
    .count({ branchId });

  // Aggregate with $match to filter by branchId
  const result = await getDBInstance(userType)
    .collection("orders")
    .aggregate([{ $match: { branchId } }, { $skip: skip }, { $limit: limit }]);

  return { data: result, totalDocumentCount };
};

export const getOrders = async ({
  page = 0,
  rowsPerPage = 10,
  userType,
  filter,
  branchId = null,
}: any) => {
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;
  const currentDate = dayjs();

  let dateFilter: any = {};
  if (filter === "today") {
    dateFilter = {
      "metadata.createdAt": {
        $gte: currentDate.startOf("day").toDate(),
        $lte: currentDate.endOf("day").toDate(),
      },
    };
  } else if (filter === "thisWeek") {
    dateFilter = {
      "metadata.createdAt": {
        $gte: currentDate.startOf("week").toDate(),
        $lte: currentDate.endOf("week").toDate(),
      },
    };
  } else if (filter === "thisMonth") {
    dateFilter = {
      "metadata.createdAt": {
        $gte: currentDate.startOf("month").toDate(),
        $lte: currentDate.endOf("month").toDate(),
      },
    };
  }

  if (userType === "branch" && branchId) {
    dateFilter.branchId = branchId;
  }

  const totalDocumentCount = await getDBInstance(userType)
    .collection("orders")
    .count(dateFilter);

  const result = await getDBInstance(userType)
    .collection("orders")
    .aggregate([{ $match: dateFilter }, { $skip: skip }, { $limit: limit }]);

  return { data: result, totalDocumentCount };
};

export const getAllOrders = async ({
  page = 0,
  rowsPerPage = 10,
  userType,
}: any) => {
  try {
    const skip = page * rowsPerPage;
    const limit = rowsPerPage;

    const db = getDBInstance(userType);
    if (!db) throw new Error("Database instance could not be initialized.");

    let matchCondition = {};

    if (userType === "branch" && branchApp.currentUser) {
      const realmId = branchApp.currentUser.id;

      const branchSession = await db
        .collection("sessions")
        .findOne(
          { realmId: new ObjectId(realmId) },
          { projection: { branchId: 1 } }
        );

      if (!branchSession) {
        console.warn("No session found for realmId:", realmId);
        return { data: [] };
      }

      const branchId = branchSession.branchId;

      if (branchId) {
        matchCondition = { branchId: branchId };
      } else {
        console.warn("Branch ID not found for realmId:", realmId);
        return { data: [] };
      }
    }

    const orders = await db
      .collection("orders")
      .aggregate([
        { $match: matchCondition },
        { $skip: skip },
        { $limit: limit },
      ]);

    if (!orders.length) {
      console.warn("No orders found for the given query.");
      return { data: [] };
    }

    // Fetch related branch and restaurant data
    const branchIds = orders
      .map((order: any) => order.branchId)
      .filter(Boolean);

    const branches = await db
      .collection("branch")
      .find({ _id: { $in: branchIds.map((id: any) => new ObjectId(id)) } });

    const restaurantIds = branches
      .map((branch: any) => branch.restaurantId)
      .filter(Boolean);

    const restaurants = await db
      .collection("restaurant")
      .find({ _id: { $in: restaurantIds.map((id: any) => new ObjectId(id)) } });

    // Map restaurant names and branch details
    const restaurantMap = new Map(
      restaurants.map((restaurant: any) => [
        restaurant._id.toString(),
        restaurant.name.en || "Unknown Restaurant",
      ])
    );

    const branchMap = new Map(
      branches.map((branch: any) => [
        branch._id.toString(),
        restaurantMap.get(branch.restaurantId.toString()) || "Unknown",
      ])
    );

    // Enrich orders with restaurant names
    const enrichedOrders = orders.map((order: any) => ({
      ...order,
      restaurantName:
        branchMap.get(order.branchId?.toString() || "") || "Unknown",
    }));

    return { data: enrichedOrders };
  } catch (error) {
    console.error("Error in getAllOrders:", error);
    throw error;
  }
};

export const getOneOrder = async ({ orderId, userType }: any) => {
  const db = getDBInstance(userType);

  let orders: any = await db.collection("orders").aggregate([
    { $match: { _id: new ObjectId(orderId) } },
    {
      $lookup: {
        from: "branch",
        localField: "branchId",
        foreignField: "_id",
        as: "branchDetails",
      },
    },
    { $unwind: { path: "$branchDetails", preserveNullAndEmptyArrays: true } },
    {
      $lookup: {
        from: "restaurant",
        localField: "branchDetails.restaurantId",
        foreignField: "_id",
        as: "restaurantDetails",
      },
    },
    {
      $unwind: {
        path: "$restaurantDetails",
        preserveNullAndEmptyArrays: true,
      },
    },
    {
      $project: {
        _id: 1,
        refNumber: 1,
        restaurantName: 1,
        status: 1,
        amount: 1,
        paymentStatus: 1,
        orderType: 1,
        items: 1,
        dineInDetails: 1,
        metadata: 1,
        orderTrackingStatus: 1,
        address: 1,
        branchDetails: {
          _id: 1,
          type: 1,
          workingHours: 1,
          contactDetails: 1,
          address: 1,
        },
        restaurantDetails: {
          _id: 1,
          name: 1,
          description: 1,
        },
      },
    },
  ]);

  return { data: orders };
};

/*  */
/* User services */
/*  */

import { getDBInstance, ObjectId } from "./dbServices";

// export const userAddWithRole = async ({ data, roleId }: any) => {
//   if (!data || !roleId) {
//     return;
//   }
//   if (typeof roleId === "string") {
//     roleId = new ObjectId(roleId);
//   }

//   const result = await getDBInstance().collection("users").insertOne({
//     email: data.email,
//     firstName: data.firstName,
//     lastName: data.lastName,
//     number: data.number,
//     createdAt: new Date(),
//     updatedAt: null,
//     isVerified: false,
//     roleId,
//   });
//   return result;
// };

// export const userUpdateWithRole = async ({ id, data, roleId }: any) => {
//   if (!id || !data || !roleId) {
//     return;
//   }
//   if (typeof id === "string") {
//     id = new ObjectId(id);
//   }

//   if (typeof roleId === "string") {
//     roleId = new ObjectId(roleId);
//   }
//   const specificRoleId = new ObjectId("661d7c37952ae5895d13ea85");
//   const findResult = await getDBInstance()
//     .collection("users")
//     .find({ roleId: specificRoleId });
//   if (findResult.length === 1) {
//     const Result = await getDBInstance()
//       .collection("users")
//       .findOne({ _id: id });
//     if (Result.roleId.toString() === specificRoleId.toString()) {
//       return false;
//     }
//     await getDBInstance()
//       .collection("users")
//       .updateOne(
//         {
//           _id: id,
//         },
//         {
//           $set: {
//             firstName: data.firstName,
//             lastName: data.lastName,
//             number: data.number,
//             updatedAt: new Date(),
//             roleId,
//           },
//         }
//       );

//     return true;
//   }
//   await getDBInstance()
//     .collection("users")
//     .updateOne(
//       {
//         _id: id,
//       },
//       {
//         $set: {
//           firstName: data.firstName,
//           lastName: data.lastName,
//           number: data.number,
//           updatedAt: new Date(),
//           roleId,
//         },
//       }
//     );

//   return true;
// };

export const getCustomers = async ({
  page = 0,
  rowsPerPage = 10,
  userType,
}: any) => {
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const totalDocumentCount = await getDBInstance(userType)
    .collection("customers")
    .count({});

  const result = await getDBInstance(userType)
    .collection("customers")
    .aggregate([
      {
        $match: {},
      },
      { $skip: skip },
      { $limit: limit },
    ]);
  // .toArray();
  return { data: result, totalDocumentCount };
};

export const getOneCustomer = async ({ id, userType }: any) => {
  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType)
    .collection("customers")
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result[0];
};

export const getUsers = async ({
  page = 0,
  rowsPerPage = 10,
  userType,
}: any) => {
  try {
    const skip = page * rowsPerPage;
    const limit = rowsPerPage;

    // Get the database instance
    const db = getDBInstance(userType);

    // Count documents in the collection
    const totalDocumentCount = await db.collection("users").count({
      source: { $ne: "Branch" },
    });

    // Aggregate with pagination
    const result = await db
      .collection("users")
      .aggregate([
        { $match: { source: { $ne: "Branch" } } },
        { $skip: skip },
        { $limit: limit },
      ]);

    return { data: result, totalDocumentCount };
  } catch (error) {
    console.error("Error fetching users:", error);
    throw new Error("Failed to fetch users");
  }
};

export const getOneUser = async ({ id, userType }: any) => {
  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType)
    .collection("users")
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);
  return result[0];
};

export const updateUser = async ({ id, name, userType }: any) => {
  if (!id || !name) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType)
    .collection("users")
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          name: name,
        },
      }
    );

  return result;
};

export const DeleteUser = async ({ id, userType }: any) => {
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType)
    .collection("users")
    .deleteOne({ _id: id });

  return result;
};

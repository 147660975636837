import { useContext } from "react";
import { MdBarChart } from "react-icons/md";
import Widget from "components/widget/Widget";
import {
  useOrdersList,
  useRestaurantValues,
} from "model/queryCalls/queryCalls";
import { UserContext } from "controller/context/userContext";

const Dashboard = () => {
  const { userType } = useContext(UserContext);
  const { data: restaurantData } = useRestaurantValues({
    page: 0,
    rowsPerPage: 1,
  });
  const branchId =
    userType === "branch"
      ? restaurantData?.data?.[0]?.branchDetails?._id
      : null;
  const { data: totalOrdersData } = useOrdersList({
    filter: null,
    branchId: userType === "branch" ? branchId : null,
  });
  const { data: todayOrdersData } = useOrdersList({
    filter: "today",
    branchId: userType === "branch" ? branchId : null,
  });
  const { data: thisWeekOrdersData } = useOrdersList({
    filter: "thisWeek",
    branchId: userType === "branch" ? branchId : null,
  });
  const { data: thisMonthOrdersData } = useOrdersList({
    filter: "thisMonth",
    branchId: userType === "branch" ? branchId : null,
  });

  const totalOrders = totalOrdersData?.totalDocumentCount || 0;
  const todayOrders = todayOrdersData?.totalDocumentCount || 0;
  const thisWeekOrders = thisWeekOrdersData?.totalDocumentCount || 0;
  const thisMonthOrders = thisMonthOrdersData?.totalDocumentCount || 0;

  // const totalAmount =
  //   totalOrdersData?.data.reduce(
  //     (acc, order) => acc + (parseInt(order.amount?.$numberInt) || 0),
  //     0
  //   ) || 0;

  return (
    <div>
      {/* Card widgets displaying metrics */}
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total Orders"}
          subtitle={totalOrders.toString()}
        />
        {/* <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Total Amount"}
          subtitle={`$${totalAmount}`}
        /> */}
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Today's Orders"}
          subtitle={todayOrders.toString()}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"This Week's Orders"}
          subtitle={thisWeekOrders.toString()}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"This Month's Orders"}
          subtitle={thisMonthOrders.toString()}
        />
      </div>

      {/* Charts */}
      {/* <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div> */}
    </div>
  );
};

export default Dashboard;

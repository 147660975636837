/*  */
/* CMS services */
/*  */

import { app, getDBInstance, ObjectId, branchApp } from "./dbServices";

export const AddCms = async ({ values, isActive, cmsData, userType }: any) => {
  if (!values || !cmsData) {
    return;
  }
  const selectedApp = userType === "branch" ? branchApp : app;
  const slug = await generateSlug({ title: values.pageName });

  const result = await getDBInstance(userType)
    .collection("cmsPages")
    .insertOne({
      pageName: { en: values.pageName },
      slug,
      content: { en: cmsData },
      isActive,
      metaData: {
        createdAt: new Date(),
        createdBy: cmsData.createdBy || selectedApp.currentUser.id,
        modifiedAt: null,
        modifiedBy: null,
      },
    });
  return result;
};

export const UpdateCms = async ({
  id,
  values,
  isActive,
  cmsData,
  userType,
}: any) => {
  if (!id || !values || !cmsData) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }
  const selectedApp = userType === "branch" ? branchApp : app;
  const slug = await generateSlug({ title: values.pageName });
  const result = await getDBInstance(userType)
    .collection("cmsPages")
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          pageName: { en: values.pageName },
          slug,
          content: { en: cmsData },
          isActive,
          metaData: {
            createdAt: new Date(),
            createdBy: cmsData.createdBy || selectedApp.currentUser.id,
            modifiedAt: new Date(),
            modifiedBy: cmsData.modifiedBy || selectedApp.currentUser.id,
          },
        },
      }
    );

  return result;
};

export const getCms = async ({ page = 0, rowsPerPage = 10, userType }: any) => {
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const totalDocumentCount = await getDBInstance(userType)
    .collection("cmsPages")
    .count({});

  const result = await getDBInstance(userType)
    .collection("cmsPages")
    .aggregate([
      {
        $match: {},
      },
      {
        $sort: {
          _id: -1,
        },
      },
      { $skip: skip },
      { $limit: limit },
    ]);

  return { data: result, totalDocumentCount };
};

export const getOneCms = async ({ id, userType }: any) => {
  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType)
    .collection("cmsPages")
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result[0];
};

export const DeleteCms = async ({ id, userType }: any) => {
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType)
    .collection("cmsPages")
    .deleteOne({ _id: id });

  return result;
};
export const generateSlug = ({ title }: any) => {
  // Convert to lowercase
  return (
    title
      // Convert to lowercase
      .toLowerCase()
      // Replace spaces with hyphens
      .replace(/\s+/g, "-")
      // Remove all non-word chars (keep only letters, numbers, and hyphens)
      .replace(/[^a-z0-9-]/g, "")
      // Replace multiple hyphens with a single hyphen
      .replace(/-+/g, "-")
  );
};

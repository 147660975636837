import { useContext } from "react";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { UserContext } from "controller/context/userContext";


const Modifiers = () => {
  const { userType } = useContext(UserContext);

  const navigate = useNavigate();

  const handleAddModifier = () => {
    navigate(`/${userType}/add-modifier`);
  };

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        <div className="mb-10 flex justify-end lg:mb-0">
          <div className="relative mb-2 w-32">
            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={handleAddModifier}
            >
              Add Modifier
            </button>
          </div>
        </div>

        <Card extra={"w-full h-full p-3"}>
          {/* <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
            {status === "loading" ? (
              <Box>
                <Skeleton sx={{ height: 190 }} />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </Box>
            ) : status === "success" && modifiersData?.data?.length !== 0 ? (
              <>
                <Table className="w-full min-w-full table-auto">
                  <Thead>
                    <Tr>
                      <Th className="border-blue-gray-50 border-b px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          SL No
                        </p>
                      </Th>
                      <Th className="border-blue-gray-50 border-b px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Name
                        </p>
                      </Th>
                      <Th className="border-blue-gray-50 border-b px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Description
                        </p>
                      </Th>
                      <Th className="border-blue-gray-50 border-b px-5 py-3 text-left">
                        Price
                      </Th>
                      <Th className="border-blue-gray-50 border-b px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Is Active
                        </p>
                      </Th>
                      <Th className="border-blue-gray-50 border-b px-5 py-3 text-center">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Actions
                        </p>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {modifiersData.data.map((modifier: any, index: any) => (
                      <Tr key={modifier._id}>
                        <Td className="border-blue-gray-50 border-b px-5 py-3">
                          <p className="text-left font-normal">{index + 1}</p>
                        </Td>
                        <Td className="border-blue-gray-50 border-b px-5 py-3">
                          <p className="font-normal">{modifier.name}</p>
                        </Td>
                        <Td className="border-blue-gray-50 border-b px-5 py-3">
                          <p className="font-normal">{modifier.description}</p> 
                        </Td>
                        <Td className="border-blue-gray-50 border-b px-5 py-3">
                          {modifier.price}
                        </Td>
                        <Td className="border-blue-gray-50 border-b px-5 py-3">
                          <Switch
                            checked={modifier.isActive}
                            onChange={() =>
                              onToggleActive(modifier._id, !modifier.isActive)
                            }
                          />
                        </Td>
                        <Td className="border-blue-gray-50 border-b px-5 py-3 text-center">
                          <div className="flex items-center gap-2 xl:justify-center">
                            <button
                              onClick={() => handleEditModifier(modifier._id)}
                              className="text-blue-500 hover:underline"
                            >
                              <MdEdit className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => onDelete(modifier._id)}
                              className="text-red-500 hover:underline"
                            >
                              <MdDelete className="h-5 w-5" />
                            </button>
                          </div>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  className="text-sm font-bold text-navy-700 dark:text-white"
                  count={modifiersData?.totalDocumentCount || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <div className="flex w-full flex-col items-center justify-center">
                <BsDatabaseFillX className="h-16 w-16 text-gray-500" />
                <p className="mt-3 text-sm font-bold text-gray-800">No Data</p>
              </div>
            )}
          </div> */}
        </Card>
      </div>
    </div>
  );
};

export default Modifiers;

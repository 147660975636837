import { Box, Skeleton,Chip, TablePagination } from "@mui/material";
import Card from "components/card";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa"; 
import { useNavigate } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { BsDatabaseFillX } from "react-icons/bs";
import ModalContainer from "components/modal";
import { useCMSPage } from "./useCMS";

const CMS = () => {
  const navigate = useNavigate();
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    userType,
    onDelete,
    deleteId,
    setDeleteId,
    isVisible,
    setIsVisible,
    CMSData,
    loading,
  } = useCMSPage();

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        <Card extra={"w-full h-full p-3"}>
          <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
            {loading ? (
                <Box>
                <Skeleton sx={{ height: 190 }} />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </Box>
            ) : CMSData?.data?.length !== 0 ? (
              <>
                <Table className="w-full min-w-full table-auto">
                  <Thead>
                    <Tr>
                      <Th className="border-b border-gray-100 px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Page Name
                        </p>
                      </Th>
                      <Th className="border-b border-gray-100 px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Slug
                        </p>
                      </Th>
                      <Th className="border-b border-gray-100 px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Content
                        </p>
                      </Th>
                      <Th className="border-b border-gray-100 px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Is Active
                        </p>
                      </Th>
                      <Th className="border-b border-gray-100 px-5 py-3 text-center">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Actions
                        </p>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {CMSData?.data?.map((data: any) => (
                      <Tr
                        key={data._id}
                        className="hover:cursor-pointer hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20"
                      >
                        <Td className="border-b border-gray-100  px-5 py-3">
                          <p className="font-regular text-left">
                            {data?.pageName?.en}
                          </p>
                        </Td>

                        <Td className="border-b border-gray-100  px-5 py-3">
                          <p className="font-regular">{data?.slug}</p>
                        </Td>
                        <Td className="border-b border-gray-100  px-5 py-3">
                          <div
                            className="font-regular"
                            dangerouslySetInnerHTML={{
                              __html:
                                data?.content?.en?.length > 200
                                  ? data?.content?.en?.substring(0, 200) + "..."
                                  : data?.content?.en,
                            }}
                          />
                        </Td>
                        <Td className="border-b border-gray-100  px-5 py-3">
                          <p>
                            {data?.isActive ? (
                              <Chip
                                label="Active"
                                color="success"
                                size="small"
                              />
                            ) : (
                              <Chip
                                label="Inactive"
                                color="error"
                                size="small"
                              />
                            )}
                          </p>
                        </Td>
                        <Td className="border-b border-gray-100  px-5 py-3 text-center">
                          <div className="flex items-center gap-2 xl:justify-center">
                      
                            <button
                              onClick={(event) => {
                                event.stopPropagation();
                                navigate(`/${userType}/cms-details/${data._id}`);
                              }}
                              className="text-blue-500 hover:underline"
                            >
                              <FaEye className="h-5 w-5" />
                            </button>
                            <button
                              onClick={(event) => {
                                event.stopPropagation();
                                navigate(`/${userType}/edit-cms/${data._id}`);
                              }}
                              className="text-blue-500 hover:underline"
                            >
                              <MdEdit className="h-5 w-5" />
                            </button>

                            <button
                              onClick={(event) => {
                                event.stopPropagation();
                                setIsVisible(true);
                                setDeleteId(data?._id);
                              }}
                              className="text-red-500 hover:underline"
                            >
                              <MdDelete className="h-5 w-5" />
                            </button>
                          </div>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  className="text-sm font-bold text-navy-700 dark:text-white"
                  count={CMSData?.totalDocumentCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <div className="flex w-full flex-col items-center justify-center">
                <BsDatabaseFillX className="h-16 w-16 text-gray-500" />
                <p className="mt-3 text-sm font-bold text-gray-800">No Data</p>
              </div>
            )}
          </div>
        </Card>
      </div>
      <ModalContainer
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        deleteId={deleteId}
        confirm={() => onDelete(deleteId)} 
      />
    </div>
  );
};

export default CMS;

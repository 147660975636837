import React, { useContext } from "react";
import * as Yup from "yup";
import { AlertContext } from "controller/context/alertContext";
import { UserContext } from "controller/context/userContext";
import { useAppSettings } from "model/queryCalls/queryCalls";
import { updateAppSettings } from "controller/services/maintenanceServices";


export const useMaintenanceMode = () => {
  const { setAlert } = useContext(AlertContext);
  const { userType } = useContext(UserContext);
  const [loading, setLoading] = React.useState(false);
  const [fullIOSMaintenance, setFullIOSMaintenance] = React.useState(false);
  const [fullAndroidMaintenance, setFullAndroidMaintenance] =
    React.useState(false);
  const [maintenanceModeAndroid, setMaintenanceModeAndroid] =
    React.useState<any>({
      loginGoogle: false,
      loginOTP: false,
      restaurantList: false,
      orderList: false,
      settingsProfile:false,
      settingsAboutUs: false,
      settingsPrivacy: false,
      settingsTerms: false,
      settingsContactUs: false,
    });

  const [maintenanceModeIOS, setMaintenanceModeIOS] = React.useState<any>({
    loginGoogle: false,
    loginApple: false,
    loginOTP: false,
    restaurantList: false,
    orderList: false,
    settingsProfile:false,
    settingsAboutUs: false,
    settingsPrivacy: false,
    settingsTerms: false,
    settingsContactUs: false,
  });
  const {
    data: maintenanceData,
    isSuccess,
    refetch,
    status,
  } = useAppSettings({
    type: "maintenance",
  });

  const maintenancePageData = {
    login: {
      pageName: "Login",
      sessions: [
        {
          name: "Google login",
          value: "loginGoogle",
        },
        {
          name: "Apple login",
          value: "loginApple",
          hideOnAndroid: true,
        },
        {
          name: "OTP login",
          value: "loginOTP",
        },
      ],
    },
    home: {
      pageName: "Home",
      sessions: [
        {
          name: "Restaurant list view",
          value: "restaurantList",
        },
      ],
    },
    orders: {
      pageName: "Orders",
      sessions: [
        {
          name: "Orders List view",
          value: "orderList",
        },
      ],
    },
    more: {
      pageName: "More",
      sessions: [
        {
          name: "Profile",
          value: "settingsProfile",
        },
        {
          name: "About us",
          value: "settingsAboutUs",
        },
        {
          name: "Privacy policy",
          value: "settingsPrivacy",
        },
        {
          name: "Terms of service",
          value: "settingsTerms",
        },
        {
          name: "Contact us",
          value: "settingsContactUs",
        },
      ],
    },
  };

  React.useEffect(() => {
    if (maintenanceData && maintenanceData._id) {
      setFullAndroidMaintenance(maintenanceData?.fullAndroidMaintenance);
      setFullIOSMaintenance(maintenanceData?.fullIOSMaintenance);
      setMaintenanceModeAndroid(maintenanceData?.maintenanceModeAndroid);
      setMaintenanceModeIOS(maintenanceData?.maintenanceModeIOS);
    }
  }, [maintenanceData, isSuccess]);

  const initialValues = {
    androidVersion: 1.0,
    iOSVersion: 1.0,
  };

  const validationSchema = Yup.object().shape({
    androidVersion: Yup.string()
      .matches(/^\d+\.\d+\.\d+$/, "Invalid version number format. Use x.x.x")
      .required("Version number is required"),
    iOSVersion: Yup.string()
      .matches(/^\d+\.\d+\.\d+$/, "Invalid version number format. Use x.x.x")
      .required("Version number is required"),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const params = {
        values,
        fullAndroidMaintenance,
        fullIOSMaintenance,
        maintenanceModeAndroid,
        maintenanceModeIOS,
        type: "maintenance",
      };
      if (maintenanceData?._id) {
        const id = maintenanceData?._id;
        await updateAppSettings({ id, ...params ,userType});
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Updated successfully",
        });
        refetch();
      } else {
        await updateAppSettings(params);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Added successfully",
        });
      }
      refetch();
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: " An error occurred",
      });
    }
  };

  const handleToggleAndroid = (section: any) => {
    setMaintenanceModeAndroid((prevState: any) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };
  const handleToggleIos = (section: any) => {
    setMaintenanceModeIOS((prevState: any) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return {
    maintenanceData,
    status,
    initialValues,
    validationSchema,
    onSubmit,
    loading,
    fullAndroidMaintenance,
    setFullAndroidMaintenance,
    fullIOSMaintenance,
    setFullIOSMaintenance,
    maintenanceModeAndroid,
    maintenanceModeIOS,
    handleToggleAndroid,
    handleToggleIos,
    maintenancePageData,
  };
};
